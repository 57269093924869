<template>
	<nuxt-link class="article" :to="localePath(`/blog/${article.slug}`)">
		<v-image
			v-if="thumbnail"
			:alt="thumbnail.alt"
			class="article__img"
			:height="thumbnail.height"
			ratio="none"
			:src="thumbnail.url"
			:width="thumbnail.width" />

		<div class="article__body">
			<v-title class="h4" tag="h3" :value="article.title" />

			<v-button
				is="span"
				color="white"
				fill="outline"
				shape="round"
				size="sm"
				value="Read article" />
		</div>
	</nuxt-link>
</template>

<script lang="ts" setup>
	import type { Article } from '@/graphql/article'
	import { getThumbnail } from '@/utilities/get-media-thumbnails'

	const props = defineProps<{
		article: Article
		isPrimary?: boolean
	}>()

	const localePath = useLocalePath()

	const thumbnail = computed(() => getThumbnail(props.article.media))
</script>

<style lang="scss" scoped>
	.article {
		display: flex;
		flex-direction: column;
		gap: 10px;
		background-color: $brand-medium;
		border-radius: 10px;
		color: $white;
		height: 100%;
		padding: 10px;

		&__img {
			aspect-ratio: 16/9;
			flex: 0 0 auto;
			border-radius: 5px;
			overflow: hidden;

			@include media-breakpoint-up('xl') {
				aspect-ratio: 2/1;
				flex: 1 1 auto;
			}

			:deep(img) {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		&__body {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 20px;

			@include media-breakpoint-up('xl') {
				flex: 0 0 auto;
				flex-direction: row;
				align-items: center;
			}
		}
	}
</style>
